import $ from 'jquery';
import 'what-input';
import Plyr from 'plyr';
import Swiper from 'swiper';


// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');
require("@fancyapps/fancybox");
require('readmore-js');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

// Enable Foundation
$(document).foundation();

// Preloder
$(document).ready(function () {
    $('#preloder').fadeOut(500);
});

// Enable Plyr player
const heroPlayer = Plyr.setup('#hero-player');
const players = Plyr.setup('.js-player');

// Read more
$('.photo-quote-text .read-more').readmore({
    moreLink: '<a href="#" class="read-more-link">Раскрыть текст <svg class="icon arrow" width="16" height="16"><use xlink:href="/assets/img/svg/symbols.svg#arrow"></use></svg></a>',
    lessLink: '<a href="#" class="read-less-link">Свернуть текст <svg class="icon arrow" width="16" height="16"><use xlink:href="/assets/img/svg/symbols.svg#arrow"></use></svg></a>',
    collapsedHeight: 442
});

// Testimonial slider
var testimonialSlider = new Swiper('.testimonial-slider', {
    speed: 400,
    spaceBetween: 20,
    loop: true,
    centeredSlides: true,
    slidesPerView: 3,
    pagination: {
        el: '.slider-pagination',
        type: 'bullets',
        bulletClass: 'slider-pagination-bullet',
        bulletActiveClass: 'slider-pagination-bullet-active',
        clickable: true
    },
    navigation: {
        nextEl: '.slider-button-next',
        prevEl: '.slider-button-prev',
    },
    breakpoints: {
        640: {
            slidesPerView: 1,
            spaceBetween: 10
        },
        1024: {
            slidesPerView: 2,
        },
    }
});

// Fancybox gallery
$.fancybox.defaults.arrows = false;
$.fancybox.defaults.buttons = ["zoom", "close"];

// Fixed nav
function fixedNav() {
    var fromTop = $(window).scrollTop(),
        hero = $('#hero'),
        nav = $('#hero-nav'),
        heroHeight = hero.innerHeight(),
        navHeight = nav.outerHeight();

    if (fromTop > heroHeight / 2) {
        $('html').css('margin-top', navHeight);
        nav.addClass('fixed');
    } else {
        $('html').css('margin-top', 0);
        nav.removeClass('fixed');
    }

    if (fromTop > heroHeight + navHeight + 100) {
        nav.addClass('active');
    } else {
        nav.removeClass('active');
    }
}

$(window).scroll(fixedNav);

// Close reveal after send email
document.addEventListener('wpcf7mailsent', function (event) {
    setTimeout(function () {
        $('.reveal').foundation('close');
    }, 3000);
}, false);
